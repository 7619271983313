import {
    DEMO_NOTICE_HEIGHT as SOURCE_DEMO_NOTICE_HEIGHT,
    RouterAfterItemType as SourceRouterAfterItemType,
    RouterBeforeItemType as SourceRouterBeforeItemType,
    RouterItemType as SourceRouterItemType,
    RouterSwitchItemType as SourceRouterSwitchItemType,
    URL_ONLY_MAIN_ITEMS_RENDER as SOURCE_URL_ONLY_MAIN_ITEMS_RENDER,
} from 'SourceComponent/Router/Router.config';

// TODO: implement URL_ONLY_MAIN_ITEMS_RENDER
export const URL_ONLY_MAIN_ITEMS_RENDER = SOURCE_URL_ONLY_MAIN_ITEMS_RENDER;

// TODO: implement DEMO_NOTICE_HEIGHT
export const DEMO_NOTICE_HEIGHT = SOURCE_DEMO_NOTICE_HEIGHT;

// ? TODO: Since override is to JavaScript, you need to handle overridden ENUMS
export const RouterItemType = { ...SourceRouterItemType };

// ? TODO: Since override is to JavaScript, you need to handle overridden ENUMS
export const RouterBeforeItemType = { ...SourceRouterBeforeItemType };

// ? TODO: Since override is to JavaScript, you need to handle overridden ENUMS
export const RouterSwitchItemType = { ...SourceRouterSwitchItemType };

// ? TODO: Since override is to JavaScript, you need to handle overridden ENUMS
export const RouterAfterItemType = { ...SourceRouterAfterItemType };
