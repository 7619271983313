import {
    Breadcrumbs as SourceBreadcrumbs,
    CartPage as SourceCartPage,
    Checkout as SourceCheckout,
    CmsPage as SourceCmsPage,
    ConfirmAccountPage as SourceConfirmAccountPage,
    ContactPage as SourceContactPage,
    CookiePopup as SourceCookiePopup,
    CreateAccountPage as SourceCreateAccountPage,
    DemoNotice as SourceDemoNotice,
    Footer as SourceFooter,
    ForgotPasswordPage as SourceForgotPasswordPage,
    Header as SourceHeader,
    HomePage as SourceHomePage,
    LoginAccountPage as SourceLoginAccountPage,
    MenuPage as SourceMenuPage,
    MyAccount as SourceMyAccount,
    NavigationTabs as SourceNavigationTabs,
    NewVersionPopup as SourceNewVersionPopup,
    NotificationList as SourceNotificationList,
    OfflineNotice as SourceOfflineNotice,
    OrderPrintPage as SourceOrderPrintPage,
    PasswordChangePage as SourcePasswordChangePage,
    ProductComparePage as SourceProductComparePage,
    RouterComponent as SourceRouterComponent,
    SearchPage as SourceSearchPage,
    SendConfirmationPage as SourceSendConfirmationPage,
    SomethingWentWrong as SourceSomethingWentWrong,
    StyleGuidePage as SourceStyleGuidePage,
    WishlistShared as SourceWishlistShared,
    withStoreRegex as sourceWithStoreRegex,
} from 'SourceComponent/Router/Router.component';

import './Router.override.style';

// TODO: implement CartPage
export const CartPage = SourceCartPage;

// TODO: implement Checkout
export const Checkout = SourceCheckout;

// TODO: implement CmsPage
export const CmsPage = SourceCmsPage;

// TODO: implement HomePage
export const HomePage = SourceHomePage;

// TODO: implement MyAccount
export const MyAccount = SourceMyAccount;

// TODO: implement PasswordChangePage
export const PasswordChangePage = SourcePasswordChangePage;

// TODO: implement SearchPage
export const SearchPage = SourceSearchPage;

// TODO: implement SendConfirmationPage
export const SendConfirmationPage = SourceSendConfirmationPage;

// TODO: implement ConfirmAccountPage
export const ConfirmAccountPage = SourceConfirmAccountPage;

// TODO: implement MenuPage
export const MenuPage = SourceMenuPage;

// TODO: implement WishlistShared
export const WishlistShared = SourceWishlistShared;

// TODO: implement ContactPage
export const ContactPage = SourceContactPage;

// TODO: implement ProductComparePage
export const ProductComparePage = SourceProductComparePage;

// TODO: implement CreateAccountPage
export const CreateAccountPage = SourceCreateAccountPage;

// TODO: implement LoginAccountPage
export const LoginAccountPage = SourceLoginAccountPage;

// TODO: implement ForgotPasswordPage
export const ForgotPasswordPage = SourceForgotPasswordPage;

// TODO: implement StyleGuidePage
export const StyleGuidePage = SourceStyleGuidePage;

// TODO: implement OrderPrintPage
export const OrderPrintPage = SourceOrderPrintPage;

// TODO: implement Header
export const Header = SourceHeader;

// TODO: implement NavigationTabs
export const NavigationTabs = SourceNavigationTabs;

// TODO: implement Footer
export const Footer = SourceFooter;

// TODO: implement NewVersionPopup
export const NewVersionPopup = SourceNewVersionPopup;

// TODO: implement NotificationList
export const NotificationList = SourceNotificationList;

// TODO: implement OfflineNotice
export const OfflineNotice = SourceOfflineNotice;

// TODO: implement CookiePopup
export const CookiePopup = SourceCookiePopup;

// TODO: implement DemoNotice
export const DemoNotice = SourceDemoNotice;

// TODO: implement SomethingWentWrong
export const SomethingWentWrong = SourceSomethingWentWrong;

// TODO: implement Breadcrumbs
export const Breadcrumbs = SourceBreadcrumbs;

// TODO: implement withStoreRegex
export const withStoreRegex = sourceWithStoreRegex;

/** @namespace Pwa/Component/Router/Component */
export class RouterComponent extends SourceRouterComponent {
    // TODO implement logic
}

export default RouterComponent;
